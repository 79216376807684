import { GEMS } from '@constant/GemDetails'

// NOTE: Assumption is that the value of level will always be of format: 'level1' for level 1 and so on.
// It doesn't accept only numeric value of something like just "1".
// It must be a combination of 'level' and <level_number>.
export const ShowColoredGem = (props: {
  level: string
  height?: string | number
  width?: number | string
}) => {
  const { height, width, level } = props

  // check for fill value as well and better to set a default svg incase there's any issue will fill data to prevent crash
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 5.5L10 17L14.5 5.5H20Z" fill={GEMS[level].fill[0]} />
      <path d="M10 17L14.5 5.5H10V17Z" fill={GEMS[level].fill[1]} />
      <path d="M10 17L5.5 5.5H10V17Z" fill={GEMS[level].fill[2]} />
      <path d="M10 17L0 5.5H5.5L10 17Z" fill={GEMS[level].fill[1]} />
      <path d="M20 5.5L15.5 0L14.5 5.5H20Z" fill={GEMS[level].fill[1]} />
      <path d="M15.5 0H10L14.5 5.5L15.5 0Z" fill={GEMS[level].fill[0]} />
      <path d="M10 5.5H14.5L10 0V5.5Z" fill={GEMS[level].fill[2]} />
      <path d="M5.5 5.5H10V0L5.5 5.5Z" fill={GEMS[level].fill[3]} />
      <path d="M10 0H4.5L5.5 5.5L10 0Z" fill={GEMS[level].fill[1]} />
      <path d="M4.5 0L0 5.5H5.5L4.5 0Z" fill={GEMS[level].fill[2]} />
    </svg>
  )
}
