import { Skeleton, TableCell, TableRow } from '@mui/material'

type TableSkeletonProp = {
  row: number
}

const TableSkeleton = ({ row }: TableSkeletonProp) => (
  <>
    {[...Array(row)].map((e, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <TableRow key={i}>
        <TableCell colSpan={12} sx={{ p: 0, px: 0.5 }}>
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
        </TableCell>
      </TableRow>
    ))}
  </>
)

export default TableSkeleton
