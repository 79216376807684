import EmptyGem from '@asset/game/header/gem/empty_gem.svg'

export const EMPTY_GEM = {
  color: 'White',
  image: EmptyGem,
}

export const GEMS = {
  level1: {
    color: 'green',
    image: '',
    fill: ['#009D39', '#0FBC5C', '#38DF86', '#8AFFC5'],
  },
  level2: {
    color: 'yellow',
    image: '',
    fill: ['#C48D00', '#E3AF00', '#F6CF19', '#FFF06D'],
  },
  level3: {
    color: 'dark blue',
    image: '',
    fill: ['#284ED0', '#4C73EA', '#7B9EFF', '#C9D8FF'],
  },
  level4: {
    color: 'red',
    image: '',
    fill: ['#B40505', '#D62B2B', '#FE5B5B', '#FFBDBD'],
  },
  level5: {
    color: 'grey',
    image: '',
    fill: ['#959595', '#B8B8B8', '#D8D8D8', '#F4F4F4'],
  },
  level6: {
    color: 'orange',
    image: '',
    fill: ['#E06000', '#F2830E', '#FEA833', '#FFDEA6'],
  },
  level7: {
    color: 'cyan blue',
    image: '',
    fill: ['#04ADDF', '#1DCAF1', '#44E6FD', '#ACF8FF'],
  },
  level8: {
    color: 'purple',
    image: '',
    fill: ['#6A0DAD', '#7C43BD', '#9B6CE0', '#C1A0E4'],
  },
  level9: {
    color: 'pink',
    image: '',
    fill: ['#F7417A', '#FF6192', '#FF85AB', '#FFB1C9'],
  },
  level10: {
    color: 'light blue',
    image: '',
    fill: ['#69D2E7', '#9BE1FF', '#C4F0FF', '#E4F8FF'],
  },
  level11: {
    color: 'brown',
    image: '',
    fill: ['#A87A53', '#BF996D', '#DAB593', '#F2D2AF'],
  },
  level12: {
    color: 'olive',
    image: '',
    fill: ['#8C7E4E', '#AEAD7A', '#CECDA2', '#E9E8C8'],
  },
  level13: {
    color: 'lime',
    image: '',
    fill: ['#8DC73F', '#A7DC5B', '#C2EB77', '#DAF88F'],
  },
  level14: {
    color: 'teal',
    image: '',
    fill: ['#2DACA6', '#4EC7C2', '#6FD9D3', '#91E6E0'],
  },
  level15: {
    color: 'maroon',
    image: '',
    fill: ['#761A1D', '#9B3035', '#C35251', '#E4847A'],
  },
  level16: {
    color: 'navy',
    image: '',
    fill: ['#001B44', '#003366', '#004488', '#0055AA'],
  },
  level17: {
    color: 'indigo',
    image: '',
    fill: ['#4B0082', '#6D1BB1', '#8E4FF7', '#B47FFF'],
  },
  level18: {
    color: 'cyan',
    image: '',
    fill: ['#00CED1', '#49EAEA', '#8FF1F4', '#C4FAFA'],
  },
  level19: {
    color: 'magenta',
    image: '',
    fill: ['#FF00FF', '#FF6EFF', '#FFA3FF', '#FFD5FF'],
  },
  level20: {
    color: 'sky-blue',
    image: '',
    fill: ['#00BFFF', '#6ACFF6', '#A8DDFF', '#D8EDFF'],
  },
  level21: {
    color: 'turquoise-blue',
    image: '',
    fill: ['#40E0D0', '#7FFFD4', '#A0FDFD', '#C0FFFF'],
  },
  level22: {
    color: 'gold',
    image: '',
    fill: ['#FFD700', '#FFDD42', '#FFE47E', '#FFECB9'],
  },
  level23: {
    color: 'orchid',
    image: '',
    fill: ['#DA70D6', '#E887E8', '#F0A0F0', '#F7B9F7'],
  },
  level24: {
    color: 'violet',
    image: '',
    fill: ['#8A2BE2', '#B15BFF', '#CB85FF', '#E2B0FF'],
  },
  level25: {
    color: 'crimson',
    image: '',
    fill: ['#DC143C', '#EE5472', '#FF809D', '#FFAFC3'],
  },
  level26: {
    color: 'chartreuse',
    image: '',
    fill: ['#7FFF00', '#9AFF40', '#B6FF80', '#CFFFAB'],
  },
  level27: {
    color: 'coral',
    image: '',
    fill: ['#FF7F50', '#FF9272', '#FFA593', '#FFBCB3'],
  },
  level28: {
    color: 'dark-orange',
    image: '',
    fill: ['#FF8C00', '#FFA240', '#FFB572', '#FFC9A3'],
  },
  level29: {
    color: 'deep-sky-blue',
    image: '',
    fill: ['#00BFFF', '#4DC7FF', '#85D4FF', '#BEE1FF'],
  },
  level30: {
    color: 'hot-pink',
    image: '',
    fill: ['#FF69B4', '#FF8CC6', '#FFADD8', '#FFCCEA'],
  },
  level31: {
    color: 'light-salmon',
    image: '',
    fill: ['#FFA07A', '#FFB699', '#FFCCA8', '#FFE3B7'],
  },
  level32: {
    color: 'medium-slate-blue',
    image: '',
    fill: ['#7B68EE', '#9D8BFF', '#B9A5FF', '#D5C0FF'],
  },
  level33: {
    color: 'pale-green',
    image: '',
    fill: ['#98FB98', '#B8FFB8', '#D4FFD4', '#E9FFE9'],
  },
  level34: {
    color: 'spring-green',
    image: '',
    fill: ['#00FF7F', '#52FFA1', '#85FFC4', '#ABFFDB'],
  },
  level35: {
    color: 'steel-blue',
    image: '',
    fill: ['#4682B4', '#6A99C2', '#8AB3D0', '#ACC9DB'],
  },
  level36: {
    color: 'tomato',
    image: '',
    fill: ['#FF6347', '#FF836C', '#FFA38F', '#FFC1B3'],
  },
  level37: {
    color: 'light-cyan',
    image: '',
    fill: ['#E0FFFF', '#E8FFFF', '#ECFFFF', '#F0FFFF'],
  },
  level38: {
    color: 'medium-orchid',
    image: '',
    fill: ['#BA55D3', '#D07EEA', '#E2A8FF', '#F1C9FF'],
  },
  level39: {
    color: 'sandy-brown',
    image: '',
    fill: ['#F4A460', '#F6B678', '#F8C99E', '#FADBC5'],
  },
  level40: {
    color: 'dark-salmon',
    image: '',
    fill: ['#E9967A', '#ECA290', '#EFB6A6', '#F3CAC0'],
  },
}
