import { forwardRef, useRef } from 'react'
import { Checkbox, Theme, SxProps } from '@mui/material'

type Props = {
  indeterminate: boolean
  sx: SxProps<Theme>
  [key: string]: any
}

const TableCheckBox = forwardRef<HTMLInputElement, Props>(({ indeterminate, sx, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  return (
    <Checkbox
      indeterminate={indeterminate}
      sx={sx}
      ref={resolvedRef}
      size="small"
      color="error"
      {...rest}
    />
  )
})

export default TableCheckBox
