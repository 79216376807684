/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import {
  Box,
  Button,
  styled,
  SxProps,
  TableCell,
  tableCellClasses,
  TableRow,
  Theme,
} from '@mui/material'
import React, { MouseEventHandler } from 'react'
import Image, { StaticImageData } from 'next/image'
import theme from '@theme'
import { ShowColoredGem } from '@commons/modules/Gems/ShowColoredGem'

type SLTableCellProps = {
  align?: 'left' | 'right' | 'center'
  textTransform?: string
  textContentType?: string // could not pass 'sentence-case' on textTransform prop due to type mismatch
  color?: string
}

// capitalize first letter of string if prosp.textTransform is 'sentence-case'
export const SLTableCell = styled(Box)<SLTableCellProps>`
  text-align: ${(props) => props.align || 'left'};
  text-transform: ${(props) => props.textTransform || 'capitalize'};
  ${(props) =>
    props.textContentType === 'sentence-case'
      ? `
        text-transform: none;
        &:first-letter {
          text-transform: capitalize;
        }
      `
      : ''};
  text-color: ${(props) => props.color || theme.palette.neutralVariant0};
  overflow: hidden;
  &:hover .row_link {
    text-decoration: underline;
  }
`

export const CellLabel = styled('span')({
  height: '20px',
  overflow: 'hidden',
  display: 'inline-block',
  textOverflow: 'ellipsis',
  width: 'calc(100% - 42px)',
  verticalAlign: 'middle',
})

export const LocationCell = styled(SLTableCell)({
  height: '20px',
  verticalAlign: 'middle',
})

export const TableCellPill = styled(Box)(() => ({
  textAlign: 'center',
  border: `2px solid ${theme.palette.success.main} `,
  borderRadius: '10px',
  backgroundColor: `${theme.palette.primary.contrastText} `,
  color: 'black',
  fontWeight: 'bold',
}))

export const StyledTableCell = styled(TableCell)((props) => ({
  [`&.${tableCellClasses.head} `]: {
    background: theme.palette.background.tableHead,
    padding: 4,
    fontWeight: 600,
    textAlign: 'left',
    '& span': {
      '& svg': {
        verticalAlign: 'middle',
      },
    },
    '&:last-child': {
      textAlign: 'left',
    },
    '&:first-of-type': {
      paddingLeft: '11px',
    },
  },
  [`&.${tableCellClasses.body} `]: {
    fontSize: 14,
    padding: 4,
    paddingLeft: props.paddingLeft || 4,
    textAlign: props.textAlign ? props.textAlign : 'left',
    borderBottom: 'none',
    '&:last-child': {
      maxWidth: 80,
    },
  },
}))

export const StyledTableRow = styled(TableRow)((props) => ({
  textAlign: 'left',
  border: 'none',
  height: '30px',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: props.userActive ? theme.palette.activeUserRow : theme.palette.neutralVariant100,
  '&:hover': {
    '& td': {
      color: props.userActive ? theme.palette.backgroundDark : theme.palette.neutralVariant100,
      cursor: props.sx?.cursor ?? 'pointer',
    },
    '& .live-text, .hover-text': {
      color: 'white',
    },
    '& .link': {
      borderBottom: `1px solid ${theme.palette.neutralVariant100} !important`,
    },
    '& .hover-button': {
      color: theme.palette.success.main,
      background: theme.palette.neutralVariant100,
      border: `1px solid ${theme.palette.success.main}`,
    },
    backgroundColor: props.userActive ? theme.palette.activeUserRow : theme.palette.primary.main,
    '& button': {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
    },
  },
}))

type SLTableCellButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>
  children: JSX.Element
}

export const SLTableCellButton = (props: SLTableCellButtonProps) => (
  <Button
    variant="outlined"
    sx={{
      minWidth: 0,
      width: '32px',
      height: '28px',
      borderRadius: '6px',
      padding: '6px 8px',
      background: theme.palette.primary.main,
      mr: '10px',
    }}
    onClick={props.onClick}
  >
    {props.children}
  </Button>
)

export const SLLink = styled('span')(() => ({
  color: `${theme.palette.neutralVariant0}`,
  textTransform: 'none',
  borderBottom: `1px solid ${theme.palette.neutralVariant0}`,
  // '&:hover': {
  //   color: theme.palette.neutralVariant100,
  //   borderBottom: `1px solid ${theme.palette.neutralVariant100}`,
  // },
}))

type SLTableLinkProps = {
  value: string
  onClick: MouseEventHandler<HTMLSpanElement>
}

export const SLTableLink = ({ value, onClick }: SLTableLinkProps) => (
  <span
    style={{
      '&:hover': { borderBottom: `1px solid ${theme.palette.neutralVariant0}`, cursor: 'pointer' },
    }}
    className="link"
    onClick={onClick}
  >
    {value}
  </span>
)

type SLTableCellImageProps = {
  imageSrc?: StaticImageData | string
  onClick?: MouseEventHandler<HTMLSpanElement>
  sx?: SxProps<Theme>
  level?: string // this is to identify that this component is being used to display gems
}

export const SLTableCellImage = ({ onClick, imageSrc, sx, level }: SLTableCellImageProps) => (
  <Box sx={{ display: 'inline', verticalAlign: 'sub', mt: '10px', ...sx }} onClick={onClick}>
    {level ? (
      <ShowColoredGem level={level} height="10" width="18" />
    ) : (
      imageSrc && (
        <Image
          src={imageSrc}
          height="18"
          width="18"
          objectFit="cover"
          className="middle"
          alt={level}
        />
      )
    )}
  </Box>
)

export const MetricsCellImage = ({ onClick, imageSrc, sx, level }: SLTableCellImageProps) => {
  const imageSize = 18

  return (
    <Box
      sx={{
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: 0, // Remove default margin
        padding: 0, // Remove default padding
        height: imageSize, // Set height to match the fontSize
        width: imageSize, // Use imageSize as width
        textAlign: 'center', // Center content horizontally
        ...sx,
      }}
      onClick={onClick}
    >
      {level ? (
        <ShowColoredGem level={level} height={imageSize} width={imageSize} />
      ) : (
        imageSrc && (
          <Image
            src={imageSrc}
            height={imageSize}
            width={imageSize} // Set width to match the height
            objectFit="contain"
            alt={level}
          />
        )
      )}
    </Box>
  )
}

export const SLTableCellButtonSm = styled(Button)(() => ({
  padding: '1px 5px',
  marginLeft: '3px',
  fontSize: '11px',
  minWidth: '50px',
}))

export const tableBackgroundForMetricsHead = {
  background:
    'linear-gradient(180deg, rgba(74, 160, 255, 0.30) 0%, rgba(2, 92, 192, 0.30) 100%), #FFF;',
  borderRadius: '6px 6px 0 0',
  fontSize: '0.875rem',
  height: '35px',
}

export const MetricsTableStyle: {
  head: React.CSSProperties
} = {
  head: {
    background: tableBackgroundForMetricsHead.background,
    textTransform: 'none',
    fontWeight: '600',
  },
}

export const MetricsNestedTableStyle: {
  head: React.CSSProperties
} = {
  head: {
    background: 'rgb(229,229,229)',
    textTransform: MetricsTableStyle.head.textTransform,
    fontWeight: MetricsTableStyle.head.fontWeight,
  },
}
