/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  Search,
} from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { Column, useRowSelect, useSortBy, useTable } from 'react-table'
import { useRouter } from 'next/router'
import {
  Dispatch,
  ForwardedRef,
  forwardRef,
  ReactNode,
  SetStateAction,
  useImperativeHandle,
  useState,
} from 'react'
import { SLButton } from '@commons/modules/Button/Button'
import { StyledTableCell, StyledTableRow } from '@commons/modules/Table/components/TableHelpers'
import ROUTES from '@constant/Routes'
import { VALID_IMAGE_EXTENSIONS } from '@constant/index'
import TableSkeleton from '@element/TableSkeleton.tsx/TableSkeleton'
import theme from '@theme'
import { SideBarData } from '@type/dto'
import { checkRegex, formatPoint, openTab } from '@util/helpers'
import { Sort, SortDirection } from '@type/common'
import PaginatedTableHeaderRow from '@module/Table/components/PaginatedTableHeaderRow'
import TableCheckBox from '@module/Table/components/TableCheckBox'

const Count = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 700,
  lineHeight: '12px',
  letterSpacing: '0px',
  textAlign: 'left',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '2px',
  display: 'inline',
  padding: '4px',
  color: theme.palette.primary.main,
}))

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  // eslint-disable-next-line no-unused-vars
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  )
}

type FilterProps = {
  globalFilter: string
  setGlobalFilter: Dispatch<SetStateAction<string>>
}
export type ActionMenu = {
  title: string
  onClick?: Function
}

export const GlobalFilter = ({ globalFilter, setGlobalFilter }: FilterProps) => {
  const [value, setValue] = useState(globalFilter)
  const [error, setError] = useState<string | null>(null)

  const onChange = (e) => {
    const reg = /^([a-zA-Z0-9, ]){0,}$/
    const valid = checkRegex(reg, e)
    if (valid) {
      setGlobalFilter(e || undefined)
      setError(null)
    } else {
      setError('Invalid Search Text.')
    }
  }

  return (
    <TextField
      variant="outlined"
      size="small"
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder="Search..."
      error={!!error}
      helperText={error || ''}
      InputProps={{
        sx: {
          borderRadius: 10,
          width: 320,
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  )
}

type PaginatedTableProps = {
  columns: Column<object>[]
  data: object[]
  rowsPerPage: number
  setRowsPerPage: Dispatch<SetStateAction<number>>
  setSearchParams?: Dispatch<SetStateAction<string>>
  isLoading: boolean
  noDataText?: string
  setPage: Dispatch<SetStateAction<number>>
  count: number
  page: number
  setSideBarData: Dispatch<SetStateAction<SideBarData>>
  noDataLink?: string
  noDataButtonText?: string
  title: string
  rowClickLink: string
  showNoDataButton: boolean
  // eslint-disable-next-line no-unused-vars
  handleRowClick?: (id: string, data?: any) => void
  getRowRoutePath?: (row: any) => string // to handle the routing from the table itself. This is to be able to handle Cmd+click situations in a centralized way
  // eslint-disable-next-line no-unused-vars
  onMouseEnter?: (data: any) => void
  isAdmin?: boolean
  containerSx?: SxProps
  hasActionCol?: boolean
  actionMenus?: ActionMenu[]
  renderDynamicActionMenu?: Function
  HeaderContent?: ReactNode
  TitleContent?: ReactNode
  sorts?: Array<Sort>
  // eslint-disable-next-line no-unused-vars
  onSorts?: (sort: Array<Sort>) => void
  useServerSideSort?: boolean
  showCheckbox?: boolean
  hidePagination?: boolean
  tableSxProps?: SxProps
  isNewWindow?: boolean
}

export type PaginatedTableForwardProps = {
  getSelectedRowIds: () => Array<string>
}

const PaginatedTable = forwardRef(
  (
    {
      columns,
      data,
      rowsPerPage,
      count,
      page,
      setPage,
      setRowsPerPage,
      setSideBarData,
      noDataText,
      noDataLink,
      noDataButtonText,
      isLoading = false,
      title,
      setSearchParams,
      rowClickLink,
      showNoDataButton = true,
      handleRowClick,
      getRowRoutePath,
      onMouseEnter,
      containerSx = {},
      hasActionCol = false,
      actionMenus = [],
      renderDynamicActionMenu,
      HeaderContent,
      TitleContent,
      onSorts,
      sorts = [],
      useServerSideSort = false,
      showCheckbox = false,
      hidePagination = false,
      tableSxProps = {},
      isNewWindow = false,
    }: PaginatedTableProps,
    ref: ForwardedRef<PaginatedTableForwardProps>,
  ) => {
    const serverSorting = count > data?.length && onSorts && useServerSideSort
    const router = useRouter()
    const path = router.pathname
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: sorts.map((sort) => ({
            id: sort.field,
            desc: sort.direction === SortDirection.DESC,
          })),
        },
      },
      useSortBy,
      useRowSelect,
      (hooks) => {
        // @ts-ignore
        hooks.visibleColumns.push((cols) => [
          ...(showCheckbox
            ? [
                {
                  disableSortBy: true,
                  id: 'selection-checkbox',
                  // eslint-disable-next-line react/prop-types
                  Header: ({ getToggleAllRowsSelectedProps }) => (
                    <TableCheckBox {...getToggleAllRowsSelectedProps()} sx={{ pl: '2px' }} />
                  ),
                  // eslint-disable-next-line react/prop-types
                  Cell: ({ row }) => (
                    // eslint-disable-next-line react/prop-types
                    <TableCheckBox {...row.getToggleRowSelectedProps()} />
                  ),
                },
              ]
            : []),
          ...cols,
        ])
      },
    )

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      setPage(newPage)
    }

    // Expose the functions to parent
    useImperativeHandle(
      ref,
      () => ({
        getSelectedRowIds: () => rows.filter((row) => row.isSelected).map((row) => row.original.id),
      }),
      [rows],
    )

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }
    const [activeActionMenu, setActiveActionMenu] = useState('')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    return (
      <TableContainer sx={{ width: '100%', ...containerSx }}>
        {title && (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="boldBody" sx={{ color: theme.palette.backgroundDark, mr: 1 }}>
                {title}
              </Typography>
              <Count>{formatPoint(count)}</Count>
              {TitleContent}
            </Box>{' '}
            {setSearchParams && (
              <GlobalFilter setGlobalFilter={setSearchParams} globalFilter={null} />
            )}
          </Box>
        )}
        {HeaderContent}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            background: theme.palette.neutralVariant100,
            width: '100%',
            ...tableSxProps,
          }}
        >
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <PaginatedTableHeaderRow
                      key={column.id}
                      onSorts={onSorts}
                      serverSorting={serverSorting}
                      column={column}
                    />
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {isLoading ? (
                <TableSkeleton row={5} />
              ) : rows.length > 0 ? (
                rows.map((row) => {
                  let actionMenuList = actionMenus
                  if (renderDynamicActionMenu) actionMenuList = renderDynamicActionMenu(row)
                  prepareRow(row)
                  return (
                    <StyledTableRow
                      {...row.getRowProps()}
                      onClick={(e) => {
                        if ((e?.ctrlKey || e?.metaKey) && getRowRoutePath) {
                          e.preventDefault()
                          // Open the link in a new tab
                          openTab(getRowRoutePath(row.original))
                          return
                        }
                        if (hasActionCol) {
                          if (
                            e.target.classList.contains('more-vert-icon') ||
                            e.target.classList.contains('MuiBackdrop-invisible') ||
                            e.target.classList.contains('action-menu-item')
                          )
                            return
                        }
                        if (handleRowClick) {
                          handleRowClick(row.original.id, row.original)
                          return
                        }
                        if (path === `/${ROUTES.AdventurePathDetails}`) {
                          router.push(ROUTES.BuildingsDetails.replace('[id]', row.original.id))
                          return
                        }

                        if (rowClickLink === ROUTES.RiggedEconomyRiggedAreaEdit) {
                          router.push(
                            ROUTES.RiggedEconomyRiggedAreaEdit.replace('[areaId]', row.original.id),
                          )
                          return
                        }

                        if (path === ROUTES.BuildingLevelsDetails) {
                          router.push(rowClickLink.replace('[id]', row.original.id))
                          return
                        }

                        if (rowClickLink === 'undefined' || rowClickLink === undefined) {
                          return
                        }
                        if (
                          (rowClickLink.includes(ROUTES.BuildingsDetailsPath) ||
                            rowClickLink.includes(ROUTES.CreatorSubmissionDetail)) &&
                          !row.original.asset
                        ) {
                          return
                        }

                        if (path === ROUTES.PublicAccount) {
                          router.push(
                            ROUTES.CreatorSubmissionDetail.replace('[id]', row.original.versionId),
                          )
                          return
                        }

                        if (rowClickLink === ROUTES.CreatorSubmissionDetail) {
                          router.push(
                            ROUTES.CreatorSubmissionDetail.replace('[id]', row.original.versionId),
                          )
                          return
                        }

                        if (isNewWindow) {
                          window.open(rowClickLink.replace('[id]', row.original.id))
                          return
                        }

                        router.push(rowClickLink.replace('[id]', row.original.id))
                      }}
                      onMouseEnter={() => {
                        if (onMouseEnter) {
                          onMouseEnter(row.original)
                          return
                        }
                        setSideBarData({
                          id: row.original.recentBuildingId
                            ? row.original.recentBuildingId
                            : row.original.id,
                          name: row.original.name,
                          description: row.original?.description ?? row.original.location,
                          polygon: row.original?.geometry?.coordinates,
                          center: row.original?.center?.coordinates,
                          images:
                            row.original?.assets?.filter(({ mimetype }) =>
                              VALID_IMAGE_EXTENSIONS.includes(mimetype),
                            ) ?? [],
                          video: {
                            filename: row.original?.asset?.video?.filename,
                            location: row.original?.asset?.video?.location,
                          },
                        })
                      }}
                      style={{
                        cursor:
                          path === ROUTES.BuildingLevelsDetails || ROUTES.MyGame
                            ? 'pointer'
                            : rowClickLink === undefined ||
                              ((rowClickLink.includes(ROUTES.BuildingsDetailsPath) ||
                                rowClickLink.includes(ROUTES.CreatorSubmissionDetail)) &&
                                !row.original.asset)
                            ? 'not-allowed'
                            : 'pointer',
                      }}
                    >
                      {row.cells.map((cell) => (
                        <StyledTableCell
                          style={{
                            cursor:
                              path === ROUTES.BuildingLevelsDetails || ROUTES.MyGame
                                ? 'pointer'
                                : !row.original.asset
                                ? 'not-allowed'
                                : 'pointer',
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </StyledTableCell>
                      ))}
                      {hasActionCol && (
                        <StyledTableCell
                          style={{
                            cursor:
                              path === ROUTES.BuildingLevelsDetails || ROUTES.MyGame
                                ? 'pointer'
                                : !row.original.asset
                                ? 'not-allowed'
                                : 'pointer',
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            id={`long-button-${row.original.id}`}
                            aria-controls={
                              activeActionMenu === row.id
                                ? `long-menu-${row.original.id}`
                                : undefined
                            }
                            aria-expanded={activeActionMenu === row.id ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(event) => {
                              setActiveActionMenu(row.id)
                              setAnchorEl(event.currentTarget)
                            }}
                          >
                            <MoreVertIcon className="more-vert-icon" />
                          </IconButton>
                          <Menu
                            id={`long-menu-${row.original.id}`}
                            MenuListProps={{
                              'aria-labelledby': `long-button-${row.original.id}`,
                            }}
                            anchorEl={anchorEl}
                            open={activeActionMenu === row.id}
                            onClose={() => {
                              setActiveActionMenu('')
                              setAnchorEl(null)
                            }}
                            PaperProps={{
                              style: {
                                maxHeight: 48 * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            {actionMenuList?.map((option) => (
                              <MenuItem
                                key={option.title}
                                // selected={option === 'Pyxis'}
                                onClick={() => {
                                  setActiveActionMenu('')
                                  setAnchorEl(null)
                                  if (option.onClick) option.onClick(row.original.id)
                                }}
                                className="action-menu-item"
                              >
                                {option.title}
                              </MenuItem>
                            ))}
                          </Menu>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  )
                })
              ) : (
                <>
                  <TableRow>
                    <TableCell colSpan={12} sx={{ borderBottom: '0', textAlign: 'center' }}>
                      <Typography variant="subGreyText" sx={{ pt: 2 }}>
                        {noDataText}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {showNoDataButton && (
                    <TableRow>
                      <TableCell
                        colSpan={12}
                        sx={{
                          borderBottom: '0',
                          textAlign: 'center',
                          pt: 0,
                        }}
                      >
                        <SLButton
                          variant="contained"
                          sx={{ width: '120px' }}
                          onClick={() => {
                            router.push(noDataLink)
                          }}
                        >
                          {noDataButtonText}
                        </SLButton>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
            {!hidePagination && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      { label: '5', value: 5 },
                      { label: '10', value: 10 },
                      { label: '25', value: 25 },
                      { label: '50', value: 50 },
                      { label: '100', value: 100 },
                      { label: 'All', value: count },
                    ]}
                    sx={{
                      '.MuiTablePagination-menuItem': {
                        padding: '5px',
                        textAlign: 'center',
                      },
                    }}
                    colSpan={12}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={count <= 0 ? 0 : page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    style={{ borderBottom: '0' }}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </Box>
      </TableContainer>
    )
  },
)

export default PaginatedTable
