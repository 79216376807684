import { FC } from 'react'
import { HeaderGroup } from 'react-table'
import { Tooltip } from '@mui/material'
import { ArrowDownward, ArrowUpward, UnfoldMore } from '@mui/icons-material'
import { StyledTableCell } from '@commons/modules/Table/components/TableHelpers'
import { Sort, SortDirection } from '@type/common'

type Props = {
  onSorts?: (sort: Array<Sort>) => void
  serverSorting: boolean
  column: HeaderGroup<object>
}

const PaginatedTableHeaderRow: FC<Props> = ({ onSorts, serverSorting = false, column }) => {
  const handleSort = () => {
    if (!column.disableSortBy && column.toggleSortBy) {
      column.toggleSortBy()
      if (serverSorting) {
        // Use server-side sorting
        let currentSortDirection = SortDirection.ASC
        if (column.isSorted) {
          currentSortDirection = column.isSortedDesc ? null : SortDirection.DESC
        }
        if (currentSortDirection) {
          onSorts([{ field: column.id, direction: currentSortDirection }])
        } else {
          onSorts([])
        }
      }
    }
  }

  return (
    <StyledTableCell {...column.getHeaderProps({})} onClick={() => handleSort()}>
      {column.render('Header')}
      <span>
        {/* eslint-disable-next-line no-nested-ternary */}
        {column.disableSortBy ? (
          ''
        ) : // eslint-disable-next-line no-nested-ternary
        column.isSorted ? (
          column.isSortedDesc ? (
            <Tooltip title="Sorted by Descending" placement="top">
              <ArrowDownward fontSize="small" sx={{ height: '18px' }} />
            </Tooltip>
          ) : (
            <Tooltip title="Sorted by Ascending" placement="top">
              <ArrowUpward fontSize="small" sx={{ height: '18px' }} />
            </Tooltip>
          )
        ) : (
          <Tooltip title="Unsorted" placement="top">
            <UnfoldMore fontSize="small" sx={{ height: '18px' }} />
          </Tooltip>
        )}
      </span>
    </StyledTableCell>
  )
}

export default PaginatedTableHeaderRow
